import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"

import "../components/style.scss"
import "../styles/styles.css"
import phoneImg from "../images/iphone-screen-repair.jpg"
import samsungPhone from "../images/samsungPhone.png"
import googlePixelPhone from "../images/googlePixelPhone.png"
import phoneLg from "../images/PhoneLg.png"
import motorolaPhone from "../images/motorolaPhone.png"
import iPadPhone from "../images/iPadPhone.png"
import { Link } from "gatsby"

const IndexPage = ({ location }) => {
  var serviceCity
  if (location.state) {
    serviceCity = location.state.serviceCity
  } else {
    serviceCity = "Portland, OR"
  }
  return (
    <Layout serviceCity={serviceCity} currentPage="repairs">
      <SEO
        title="Cell Phone Repair Services"
        description={
          "At PhoneFix Pro we Repair broken iPhones and Broken Android devices on a Professional level with fast repairs in Portland, OR"
        }
      />
      <div>
        <section className="section">
          <div className="container">
            <Breadcrumbs pageName="Repair" />
            <h1 className="is-size-2">Cell Phone and Tablet Repair Services</h1>
            <div className="columns is-multiline">
              <div className="column is-one-half">
                <img
                  className="ls-s-1"
                  src={phoneImg}
                  alt="iPhone Screen Repair Service"
                  style={{ maxWidth: 75 + `%` }}
                />
              </div>
              <div className="column is-one-half">
                <p>
                  At PhoneFix Pro we repair broken iPhones and Android devices
                  on a Professional level with fast repairs. Our techs can fix
                  your broken Apple device and Android Device in most cases in
                  under 45 minutes, no appointments needed. Our cell phone
                  repairs are on a first-come, first-served basis in Portland,
                  OR.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="repairPhonesGrid">
        <Link
          className="repairPhonesGridItem"
          to="/cell-phone-repair/iphone-repair"
        >
          <div className="repairPhonesGridImgWrapper">
            <img
              src={"https://pngimg.com/uploads/iphone_11/iphone_11_PNG11.png"}
              alt={`iPhone Repair Services`}
              className="repairPhonesGridImg"
            />
          </div>
          <h3 className="repairPhonesGridItemHeader">
            iPhone <br />
            Repair Services
          </h3>
        </Link>
        <Link
          to="/cell-phone-repair/ipad-repair"
          className=" repairPhonesGridItem"
        >
          <div className="repairPhonesGridImgWrapper">
            <img
              src={iPadPhone}
              alt={`iPad Repair Service`}
              className="repairPhonesGridImgIpad"
            />
          </div>
          <h3 className="repairPhonesGridItemHeader">
            iPad Repair <br />
            Services
          </h3>
        </Link>
        <Link
          to="/cell-phone-repair/samsung-repair"
          className="repairPhonesGridItem"
        >
          <div className="repairPhonesGridImgWrapper">
            <img
              src={samsungPhone}
              alt={`Samsung Repair Service`}
              className="repairPhonesGridImg"
            />
          </div>
          <h3 className="repairPhonesGridItemHeader">
            Samsung <br />
            Repair Services
          </h3>
        </Link>
        <Link
          to="/cell-phone-repair/google-pixel-repair"
          className="repairPhonesGridItem"
        >
          <div className="repairPhonesGridImgWrapper">
            <img
              src={googlePixelPhone}
              alt={`Google Pixel Repair Service`}
              className="repairPhonesGridImg"
            />
          </div>
          <h3 className="repairPhonesGridItemHeader">
            Google Pixel <br />
            Repair Services
          </h3>
        </Link>
        <Link
          to="/cell-phone-repair/lg-repair"
          className="repairPhonesGridItem"
        >
          <div className="repairPhonesGridImgWrapper">
            <img
              src={phoneLg}
              alt={`LG Repair Service`}
              className="repairPhonesGridImg"
            />
          </div>
          <h3 className="repairPhonesGridItemHeader">
            LG <br />
            Repair Services
          </h3>
        </Link>
        <Link
          to="/cell-phone-repair/motorola-repair"
          className="repairPhonesGridItem"
        >
          <div className="repairPhonesGridImgWrapper">
            <img
              src={motorolaPhone}
              alt={`Motorola Repair Service`}
              className="repairPhonesGridImg"
            />
          </div>
          <h3 className="repairPhonesGridItemHeader">
            Motorola <br />
            Repair Services
          </h3>
        </Link>
      </div>
    </Layout>
  )
}
export default IndexPage
