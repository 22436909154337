import React from 'react';

import './style.scss';
import { Link } from 'gatsby';
let blankName = `Cell Phone Repair`;

const Breadcrumbs = ({ pageName }) => (
  <nav className='breadcrumb' aria-label='breadcrumbs'>
    <ul>
      <li key='1'>
        <Link to='/'>
          {`PhoneFix PRO - Best ` +
            (pageName || blankName) +
            ` in Portland, OR
 `}
        </Link>
      </li>
      <li key='2'>
        <Link to='/cell-phone-repair'>Cell Phone Repair Services</Link>
      </li>
      <li key='3' className='is-active'>
        <a href='/' aria-current='page'>
          {pageName}
        </a>
      </li>
    </ul>
  </nav>
);

export default Breadcrumbs;
